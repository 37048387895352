.accordion {
  &.employee {
    border-width: 4px;
    // margin-bottom: 188px;
    margin-bottom: 135px;
    @media (max-width: 992px) {
      margin-bottom: 80px;
      border-width: 2px;
    }
    .card {
      overflow: visible;
      border-bottom: 4px solid #3a3c45;

      &.cardMultiple {
        border-bottom: 0px;
      }

      @media (max-width: 992px) {
        border-bottom: 2px solid #3a3c45;
      }
      .card-header {
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        @media (max-width: 992px) {
          flex-direction: column;
          padding: 25px 0;
        }

        .bootstrap-select {
          width: 350px !important;
          display: none;
          margin-top: -10px;
          @media (max-width: 992px) {
            width: 100% !important;
            margin-top: 20px;
            padding-left: 0;
          }
          > .dropdown-toggle {
            @media (max-width: 992px) {
              padding: 12px 8px 12px 0px;
            }
            &:after {
              display: none;
            }
            .filter-option-inner-inner {
              color: #333333;
              line-height: 24px;
              @media (max-width: 992px) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
          .dropdown-menu {
            border-radius: 0 !important;
            top: -3px !important;
            .dropdown-item {
              padding: 13px 1.5rem;
              line-height: 100%;
              &.active,
              &:active {
                background-color: transparent;
                color: $glava-black;

                @include site(weber) {
                  color: $weber-black;
                }

                @include site(gyproc) {
                  color: $gyproc-black;
                }
              }
            }
          }
          select {
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;
          }
          button {
            background-color: transparent;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid #c4c4c4;
            border-radius: 0;
            box-shadow: none;
            background-image: url("../images/icons/arrow-down.svg");
            background-repeat: no-repeat;
            background-size: 16px 10px;
            background-position: center right;
            font-weight: 600;
            font-size: 17px;
            line-height: 100%;
            letter-spacing: 0.24px;
            padding: 17px 8px;
            color: #333333;
            &:focus {
              outline: none !important;
            }
          }
          option {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #333333;
          }
          &:focus {
            outline: none;
          }
        }

        h2 {
          //   flex-basis: calc(100% - 350px);
          flex-basis: auto;
          position: relative;
          max-width: 96%;
          @media (max-width: 992px) {
            max-width: 100%;
          }

          button {
            font-family: $font-regular;
            @include font-size(36);
            line-height: 44px;
            letter-spacing: 0.12px;
            font-weight: 600;
            font-style: normal;
            color: #2a2a2a;
            width: 100%;
            @media (max-width: 992px) {
              @include font-size(24);
              line-height: 100%;
              width: 90%;
            }

            &.collapsed {
              font-weight: 600;
            }
            &::after {
              @include background-image("icons/search-arrow-icon.svg", 24, 18);
              margin-left: 20px;
              transform: rotate(90deg);
              top: 15px;
              right: -40px;
              @media (max-width: 992px) {
                @include background-image("icons/search-arrow-icon.svg", 22, 16);
                right: -3px;
                top: 3px;
              }
            }
            &[aria-expanded="true"] {
              &::after {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
      .card-body {
        width: 100%;
        min-height: 200px;
      }
      &.card--open {
        .card-header {
          h2 {
            max-width: 65%;
            @media (max-width: 1200px) {
              max-width: 55%;
            }
            @media (max-width: 992px) {
              max-width: 100%;
            }
            & + .bootstrap-select {
              display: block;
            }
          }
          .bootstrap-select {
            display: none;
          }
        }
      }
    }

    .employee__item {
      margin: 10px 0 75px 0;
      @media (max-width: 992px) {
        margin: 0 0 35px 0;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0.24px;
        text-decoration-line: underline;
        margin: 30px 0 10px 0;
        color: #121212;
        @media (max-width: 992px) {
          font-size: 14px;
          margin: 15px 0 5px 0;
        }
      }
      &--position {
        margin-bottom: 20px;
        display: block;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.24px;
        color: #3a4349;
        @media (max-width: 992px) {
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 17px;
        }
      }
      &--email,
      &--phone {
        display: block;
        margin-bottom: 0px;
        line-height: 100%;
        a {
          font-size: 18px;
          line-height: 100%;
          letter-spacing: 0.24px;
          color: #3a4349;
          @media (max-width: 992px) {
            font-size: 14px;
          }
        }
      }
      &--phone {
        margin-top: 18px;
        margin-bottom: 0;
        @media (max-width: 992px) {
          margin-top: 10px;
        }
        @media (min-width: 992px) {
          a {
            text-decoration: none;
            pointer-events: none;
          }
        }
      }
    }
  }

  &.show__employee {
    margin-bottom: 185px;
    @media (max-width: 992px) {
      margin-bottom: 80px;
    }
  }

  &--no-accordion {
    border: none!important;
    .card {
      border: none!important;
      .card-header {
        display: none!important;
      }
      .card-body{
        padding: 0!important;
      }
      .collapse:not(.show) {
        display: block!important;
      }
      .accordion__footer {
        margin: 0!important;
      }
    }
  }
}

.employees_title {
  @include font-size(36);
  line-height: 44px;
  font-weight: 600;
  color: #2A2A2A;
  margin-bottom: 48px;
  @media (max-width: 1024px){
    @include font-size(24);
    line-height: 32px;
    margin-bottom: 20px;
  }
}

.employees_ingress {
  font-size: 1.25rem;
  line-height: 32px;
  color: #333333;
  margin-bottom: 44px;
  padding-right: 26%;
  @media (max-width: 992px){
    padding-right: 0px;
  }
}
